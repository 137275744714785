<template>
  <span>
    <span v-html="query_message"></span>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredUnits"
      :search="search"
      item-key="id"
      multi-sort
      :single-select="false"
      show-select
      :custom-filter="filterOnlyCapsText"
      :items-per-page.sync="itemsperpage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>一覧</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            text
            @click="clearCondition"
            v-show="isCondition"
            class="red--text"
          >
            検索条件クリア
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search_async"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            clearable
            class="ml-4"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`body.append`]>
        <tr>
          <td></td>
          <td>
            <v-select
              v-model="year"
              :items="years"
              multiple
              clearable
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="w_category"
              :items="categories"
              clearable
            ></v-select>
          </td>
          <td>
            <v-select v-model="maker" :items="makers" clearable></v-select>
          </td>
          <td colspan="2"></td>
          <td>
            <v-select v-model="country" :items="countries" clearable></v-select>
          </td>
          <td colspan="13"></td>
        </tr>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model.lazy="ccFrom"
          type="number"
          outlined
          filled
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.lazy="ccTo"
          type="number"
          outlined
          filled
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn
          color="primary"
          accesskey="u"
          :disabled="selected.length === 0"
          @click="update"
          >更新(<u>u</u>)</v-btn
        >
      </v-col>
      <v-col cols="1">
        <v-btn
          color="secondary"
          accesskey="n"
          :disabled="selected.length !== 0"
          @click="updateNolink"
          >リンクなし(<u>n</u>)</v-btn
        >
      </v-col>
      <v-col cols="11">
        <v-alert v-show="showSuccess" dense text type="success">
          更新しました。
        </v-alert>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const {
  mapGetters: unitsGetters,
  mapActions: unitsActions,
} = createNamespacedHelpers("units");

export default {
  name: "UnitTable",
  data: () => ({
    search: "",
    search_async: "",
    search_temp: "",
    ccFrom: "",
    ccTo: "",
    loading: false,
    year: null,
    itemsperpage: 10,
    selected: [],
    w_category: null,
    maker: null,
    country: null,
    showSuccess: false,
  }),
  computed: {
    ...unitsGetters({
      units: "units",
      unitsDb: "unitsDb",
      countries: "country",
      years: "year",
      makers: "maker",
      categories: "w_category",
      query: "query",
    }),
    query_message() {
      if (!this.query.country) {
        return "";
      }
      return (
        this.query.country +
        "|" +
        this.query.maker +
        "|" +
        this.query.w_category +
        "|" +
        this.query.cc +
        "|" +
        this.query.model
      );
    },
    headers() {
      return [
        {
          text: "モデルイヤー",
          value: "year",
          filter: (value) => {
            if (!this.year) return true;
            if (this.year.length === 0) return true;
            return this.year.indexOf(value) > -1;
          },
        },
        {
          text: "カテゴリ",
          value: "w_category",
          filter: (value) => {
            if (!this.w_category) return true;
            return value === this.w_category;
          },
        },
        {
          text: "メーカー",
          value: "maker",
          filter: (value) => {
            if (!this.maker) return true;
            return value === this.maker;
          },
        },
        { text: "モデル名", value: "model" },
        { text: "排気量", value: "cc" },
        {
          text: "仕向地",
          value: "country",
          filter: (value) => {
            if (!this.country) return true;
            return value === this.country;
          },
        },
        { text: "jan", value: "jan" },
        { text: "feb", value: "feb" },
        { text: "mar", value: "mar" },
        { text: "apr", value: "apr" },
        { text: "may", value: "may" },
        { text: "jun", value: "jun" },
        { text: "jul", value: "jul" },
        { text: "aug", value: "aug" },
        { text: "sep", value: "sep" },
        { text: "oct", value: "oct" },
        { text: "nov", value: "nov" },
        { text: "dec", value: "dec" },
        { text: "数", value: "total" },
      ];
    },
    isCondition() {
      if (
        this.search ||
        this.year ||
        this.category ||
        this.maker ||
        this.country ||
        this.ccTo ||
        this.ccFrom
      ) {
        return true;
      }
      return false;
    },
    filteredUnits() {
      if (!this.ccTo && !this.ccFrom) {
        return this.units;
      }
      if (this.ccFrom && this.ccFrom > 0 && this.ccTo) {
        return this.units.filter(
          (e) =>
            parseInt(e.cc, 10) >= this.ccFrom && parseInt(e.cc, 10) <= this.ccTo
        );
      }
      if (this.ccTo) {
        return this.units.filter((e) => parseInt(e.cc, 10) <= this.ccTo);
      }
      return this.units.filter((e) => parseInt(e.cc, 10) >= this.ccFrom);
    },
  },
  watch: {
    query: function () {
      // console.log(this.query);
      if (!this.query.maker) {
        this.clearCondition();
        return;
      }
      this.maker = this.query.maker;
      if (this.query.country === "ITA") {
        this.country = "ITALY";
      } else {
        this.country = this.query.country;
      }
      this.w_category = this.query.w_category;
      this.ccFrom = parseInt(parseInt(this.query.cc, 10) * 0.8, 10);
      this.ccTo = parseInt(parseInt(this.query.cc, 10) * 1.2, 10);
      if (this.query.model.length > 3) {
        this.search_async = this.query.model.substr(0, 3);
      } else {
        this.search_async = this.query.model;
      }
      let year = parseInt(this.query.year, 10);
      let years = [];
      for (let i = -1; i < 2; i++) {
        years.push((year + i).toString());
      }
      // console.log(years);
      this.year = years;
      const ids = [];
      if (this.unitsDb[this.query.id]) {
        Object.keys(this.unitsDb[this.query.id]).forEach((e) => {
          if (!isNaN(e)) {
            ids.push({ id: e });
          }
        });
        this.selected = ids;
      }
    },
    search_async: function (val) {
      this.search_temp = val;
      window.setTimeout(() => {
        if (this.search_temp === this.search_async) {
          this.search = this.search_async;
        }
      }, 500);
    },
  },
  async mounted() {
    await this.fetchUnits();
  },
  methods: {
    ...unitsActions(["fetchUnits", "writeUnits"]),
    clearCondition() {
      this.search = null;
      this.search_async = null;
      this.year = null;
      this.w_category = null;
      this.maker = null;
      this.country = null;
      this.ccFrom = null;
      this.ccTo = null;
      return false;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    getFiltered(items) {
      var con = {};
      con.search = this.search;
      con.year = this.year;
      con.category = this.category;
      con.maker = this.maker;
      con.destination = this.destination;
      window.localStorage.setItem("baseproductcon", JSON.stringify(con));
      for (let i = 0; i < items.length; i++) {
        this.getData(items[i]);
      }
    },
    updateNolink() {
      if (this.selected.length !== 0) {
        return;
      }
      const u = { [this.query.id]: "nolink" };
      this.writeDb(u);
    },
    update() {
      if (this.selected.length === 0) {
        return;
      }
      let ids = [];
      this.selected.map((e) => ids.push(e.id));
      let u = this.selected.reduce(
        (u, e) => ({ ...u, [e.id]: new Date().getTime() }),
        {}
      );
      u.ids = ids;
      u = { [this.query.id]: u };
      this.writeDb(u);
    },
    writeDb(unit) {
      this.writeUnits(unit);
      this.selected = [];
      this.showSuccess = true;
      window.setTimeout(this.showSuccess, 3000);
    },
  },
};
</script>