<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-show="!loading">
      <base-product-table
        :post-pas="pas"
        :post-mydb="mydb"
        :show-related="true"
        @click="onClickEvent"
        :itemsperpage="5"
        class="mb-2"
      ></base-product-table>
      <base-units-table></base-units-table>
    </div>
  </v-container>
</template>
<script>
import axios from "axios";
import Processing from "../mixins/processing";
import { taffy } from "taffydb";
import csv from "csvtojson";
import BaseProductTable from "@/components/Bases/BaseProductTable";
import { createNamespacedHelpers } from "vuex";
import BaseUnitsTable from "@/components/Bases/UnitsTable.vue";
const { mapActions: unitsActions } = createNamespacedHelpers("units");
export default {
  mixins: [Processing],
  components: {
    BaseProductTable,
    BaseUnitsTable,
  },
  name: "HelloWorld",
  data: () => ({
    search: "",
    loading: true,
    modelyear: null,
    category: null,
    maker: null,
    destination: null,
    pas: [],
    mydb: null,
    data: "",
  }),
  mounted() {
    document.title = "製品写真 | 他社商計";
    this.getData();
  },
  methods: {
    ...unitsActions(["setCondition"]),
    async getData() {
      this.pas = [];
      const data = await axios
        .get("https://cova.sakuraweb.com/data/data.php")
        .then();
      this.data = data.data;
      window.localStorage.setItem("data", this.data);
      this.loadTaffy();
    },
    loadTaffy() {
      csv({ delimiter: "\t" })
        .fromString(this.data)
        .then((json) => {
          this.loading = false;
          // console.log(json);
          json = json.filter(
            (e) => e.modelyear > "2018" && e.modelyear < "2023"
          );
          this.pas = json;
          this.mydb = taffy(JSON.stringify(json));
        });
    },
    onClickEvent(data) {
      this.setCondition({
        country: data.destination,
        maker: data.maker,
        cc: data.cc,
        year: data.modelyear,
        model: data.modelname,
        id: data.id,
        w_category: data.category,
      });
      // console.log(data);
    },
  },
};
</script>
